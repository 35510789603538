import { graphql } from 'gatsby'
import React from 'react'

import '../../styles/global.css'
import img1 from '../../assets/blog/1/1.png'
import img2 from '../../assets/blog/1/2.png'
import img3 from '../../assets/blog/1/3.png'
import img4 from '../../assets/blog/1/4.png'
import img5 from '../../assets/blog/1/5.png'
import img6 from '../../assets/blog/1/6.gif'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { Link } from '../../components/common/link'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Jak si nejlépe naplánovat a užít dovolenou" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Jak si nejlépe naplánovat a užít dovolenou" />
        <ContentSubPage>
          <p>
            Léto, sluníčko, prázdniny, moře…zima, hory, lyže, teplý grog…Taky se
            už těšíte na ty báječné chvíle odpočinku daleko od každodenních
            starostí? Kdo by se netěšil. Ale ten stres před tím! Plánování,
            přípravy, organizace, balení…hlavně na nic důležitého nezapomenout!
            Máme vše? Co je ještě potřeba zařídit, objednat, zjistit, koupit
            atd.? Zapisujete si věci do diáře, kalendáře, poznámkového bloku, ať
            už papírového nebo elektronického, a některé věci nosíte jen v
            hlavě?
          </p>
          <p>
            Co takhle mít to vše na jednom místě a uspořádané podle vaší
            myšlenkové mapy? S aplikací{' '}
            <Link to="/" target="_blank">
              Flagis
            </Link>{' '}
            je to hračka! Pomocí štítků si vytvoříte svoji vlastní strukturu
            zobrazení úkolů (
            <Link to="/features/smart-view" target="_blank">
              Smart View
            </Link>
            ), kategorie, podkategorie, vše podle vašich potřeb a vám na míru.
            Pokud potřebujete nějaký úkol delegovat na vaše blízké, žádný
            problém. Jednoduše vytvoříte úkol a pošlete jej vaší „drahé
            polovičce“ či osobě, která má úkol realizovat. Komunikace je
            jednoduchá a jasná (
            <Link to="/features/clear-responsibility" target="_blank">
              více zde
            </Link>
            ).
          </p>
          <p>
            S Flagisem na nic nezapomenete a budete mít vše na jednom místě a
            vždy po ruce. Zadáte si úkoly, poznámky, místa která chcete
            navštívit, vybrané restaurace, nákupní seznam…prostě vše, co je
            potřeba, aby vaše dovolená proběhla v pohodě.
          </p>
          <p>
            Pro inspiraci…ukázkové Smart View zaměřené na dovolenou a cestování:
          </p>
          <div className="flex justify-center mb-10">
            <img src={img1} className="center object-contain" />
          </div>
          <p>Vytvoříte si svoje štítky…</p>
          <div className="flex justify-center mb-10">
            <img src={img2} className="object-contain" />
          </div>
          <p>
            …které přiřadíte ke konkrétnímu úkolu, abyste ho co nejrychleji
            našli.
          </p>
          <div className="flex justify-center mb-10">
            <img src={img3} className="object-contain" />
          </div>
          <p>
            My jsme si naši kategorii „Cestování“ rozdělili na letní dovolenou,
            zimní dovolenou a celoroční výlety. Do tipů na výlety si můžete
            přidávat zajímavá místa, která chcete navštívit. Když pak budete
            plánovat nějaký výlet, stačí jedním kliknutím vybrat podkategorii
            např. „ZOO“, „Hrady a zámky“, „Příroda“ či „Města“ a vybrat z vašeho
            seznamu vhodné místo. :) K tomuto místu si pak můžete do popisu
            úkolu zapsat různé poznámky (např. restaurace v okolí, možnosti
            parkování, ceny vstupného, odkaz na www stránky s informacemi o
            daném místě atd.).
          </p>
          <div className="flex justify-center mb-10">
            <img src={img4} className="object-contain" />
          </div>
          <p>
            Nestíháte? Nebojte se úkoly delegovat! Obdrželi jste úkol? Dejte
            odesílateli vědět, zda úkol splníte či nikoliv.
          </p>
          <div className="flex justify-center mb-10">
            <img src={img5} className="object-contain" />
          </div>
          <p>
            Co bychom si počali bez nákupního seznamu?!?! A to nejen věcí
            týkajících se dovolené. Do kategorie „Nákupy“ si zapisujte všechno,
            co potřebujete koupit. Tuto kategorii si můžete dále rozdělit na
            „Domácnost“, „Škola“, „Dovolená“, „Auto“…Tím si velmi rychle
            zobrazíte jen ty položky, které právě potřebujete vidět.
          </p>
          <div className="flex justify-center mb-10">
            <img src={img6} className="object-contain" />
          </div>
          <p>Jak vidíte, je to velmi jednoduché, intuitivní a přehledné.</p>
          <p>S Flagisem na nic nezapomenete!</p>
          <p className="text-lg font-bold">Flagis ON. Chaos OFF.</p>
          <p>Krásnou a pohodovou dovolenou vám přeje</p>
          <p>Flagis tým</p>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
